"use client";

import React from "react";
import RouterLink from "next/link";
import { Link } from "@jasperlabs/jux-next";
import AppHeaderErrorOrNotFound from "components/layout/AppHeader/AppHeaderErrorOrNotFound";

import ContactUs from "../tenants/components/TenantSupportLink";

function NotFound() {
	return (
		<>
			<AppHeaderErrorOrNotFound />
			<div className="h-full min-h-screen bg-white pb-7 pt-6 md:pb-8 md:pt-12 lg:pb-9">
				<div className="max-w-container-lg mx-auto w-full px-5">
					<div className="max-w-container-xs mx-auto w-full">
						<h1 className="text-heading-sm mb-6 text-center md:mb-9">
							Sorry, but the page you were looking for could not be found.
						</h1>
					</div>
					<div className="max-w-container-xs mx-auto w-full">
						<p className="text-body-xl text-color-body text-center">
							You can return to our{" "}
							<Link as={RouterLink} href="/" className="text-inherit">
								front page
							</Link>{" "}
							or <ContactUs className="text-inherit">drop us a line</ContactUs>{" "}
							if you can&#39;t find what you&#39;re looking for.
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default NotFound;
